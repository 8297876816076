export const allSports = [
    { value: "surf", key: "surf", label: "Surf" },
    { value: "paddlesurf", key: "paddlesurf", label: "Paddle surf" },
    { value: "buceo", key: "buceo", label: "Buceo" },
    { value: "skate", key: "skate", label: "Skate" },
    { value: "golf", key: "golf", label: "Golf" },
    { value: "kitesurf", key: "kitesurf", label: "Kite surf" },
    { value: "esquiAcuatico", key: "esquiAcuatico", label: "Esquí acuático" },
    { value: "esqui", key: "esqui", label: "Esquí" },
    { value: "karate", key: "karate", label: "Karate" },
    { value: "Surfskate", key: "Surfskate", label: "Surfskate" }
];

// export const ListingPageTab = ["Description","Next sessions","Cancelation policy"];
export const ListingPageTab = ["Descripción", "Próximas sesiones", "Política cancelación"];
// "Location"
// Lessons and packs== Comprar clase
// Lessons calendar== Apuntarme a clase
// Reviews== Valoraciones
// Pictures== Fotos
export const profilePageTabs = [ { label: "Apuntarme a clase", type: "calender" },{ label: "Comprar clase", type: "bucket" }, { label: "Valoraciones", type: "star" }, { label: "Fotos", type: "image" }];

export const schoolDashboardTabs = [
    { key: "date", value: "Date", label: "Fecha" },
    { key: "day", value: "Day", label: "Día" },
    { key: "availableSeats", value: "Available Seats", label: "Asientos disponibles" },
    { key: "totalSeats", value: "Total Seats", label: "Asientos totales" }
];

export const singleDashboardTabs = [
    { key: "name", value: "Name", label: "Nombre" },
    // { key: "guestJoined",value: "Guest Joined", label: "Invitado unido" },
    { key: "phoneNumber", value: "Phone Number", label: "Número de teléfono" },
    // { key: "cancelled",value: "Cancelled", label: "Cancelado" }
];

export const timePeriodTab = [
    { key: "monthly", value: "monthly", label: "Mes" },
    { key: "weekly", value: "weekly", label: "Semana" }
];